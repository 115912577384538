<i18n>
ru:
  changeButton: Изменить
  timeDelivery: Время доставки
  timeInHall: Время заказа
  timeSelfService: Время самовывоза
  timeUnset: Выберите время
ua:
  changeButton: Змінити
  timeDelivery: Час доставки
  timeInHall: Час замовлення
  timeSelfService: Час самовивозу
  timeUnset: Вибрати час
us:
  changeButton: Change
  timeDelivery: Delivery time
  timeInHall: Order time
  timeSelfService: Pickup time
  timeUnset: Select time
</i18n>

<template>
  <div
    v-if="orderTypeText"
    class="v-address-form-full"
  >
    <label
      class="for-form-control"
      v-html="orderTypeText"
    />
    <div class="v-delivery-time-button v-form-control">
      <div class="v-delivery-time-button-time">
        <span
          v-if="deliveryTimeASAP"
          v-html="translate('deliveryTimeRathloriel.deliveryASAPLabel')"
        />
        <span
          v-else
          v-html="deliveryTimeText"
        />
      </div>
      <arora-button
        class-name="v-btn v-btn-link-primary v-btn-text"
        ignore-settings
        :disabled="!restrictions"
        :label="translate('deliveryTimeRathDinen.changeButton')"
        data-test-id="change-delivery-time-dinen"
        @click="async () => await callForPopup()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LimitOrderPeriod } from '~types/clientStore'
import type { DeliveryTimeCommon } from '~types/props'

import { OrderType } from '~api/consts'

const { deliveryTimeBySettings } = defineProps<DeliveryTimeCommon>()

const clientStore = useClientStore()
const popupStore = usePopupStore()
const { translate } = useI18nSanitized()
const { eventOn } = useEmitter()
const { fromMillisInZone } = useDateTime()
const appConfig = useAppConfig()

const restrictions = computed<LimitOrderPeriod | null>(
  () => clientStore?.TimeRestrictions.data
)

onMounted(() => {
  if (clientStore.ClientState.data?.StateOrderData?.DeliveryTime ?? 0 > 0) refreshTime()

  eventOn('v-time-refresh', () => {
    refreshTime()
  })
})

const orderTypeText = computed<string | null>(() => {
  switch (clientStore.ClientState?.data?.OrderType) {
    case OrderType.CourierDelivery:
      return translate('deliveryTimeRathDinen.timeDelivery')
    case OrderType.NoShipment:
      return translate('deliveryTimeRathDinen.timeSelfService')
    case OrderType.InHall:
      return translate('deliveryTimeRathDinen.timeInHall')
    default:
      return null
  }
})

const enabledASAP = computed<boolean>(() => {
  return (
    (restrictions.value &&
      restrictions.value.ASAP?.AvailableNow &&
      orderTypeSelected.value) ??
    false
  )
})
const enabledScheduled = computed<boolean>(() => {
  return (
    (restrictions.value &&
      restrictions.value.Scheduled?.EnabledByTerminalSettings &&
      orderTypeSelected.value) ??
    false
  )
})
const orderTypeSelected = computed<boolean>(() => {
  return clientStore.ClientState?.data?.OrderType !== OrderType.Default
})

const deliveryTimeASAP = computed<boolean | null>(() => {
  const asap = clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? true
  if (enabledASAP.value && asap) {
    return asap
  } else if (enabledScheduled.value && !asap) {
    return asap
  }

  return null
})

const deliveryTimeText = ref<string>(translate('deliveryTimeRathDinen.timeUnset'))

function refreshTime(): void {
  if (deliveryTimeASAP.value) return

  const time: number =
    (clientStore.ClientState?.data?.StateOrderData?.DeliveryTime ?? 0) * 1000
  const scheduled = restrictions.value?.Scheduled?.EnabledByTerminalSettings
    ? restrictions.value?.Scheduled?.Schedule?.Dates
    : null

  if (scheduled && time > 0) {
    const dt = fromMillisInZone(time, appConfig.RestaurantSettingsPreRun.GMT)
    const key = dt.toFormat('yyyyMMdd')

    for (const period of scheduled[key]?.RestrictedPeriods ?? []) {
      if (time >= period.Start && time <= period.End) {
        deliveryTimeText.value = deliveryTimeBySettings(dt)

        return
      }
    }
  }
  deliveryTimeText.value = translate('deliveryTimeRathDinen.timeUnset')
}

async function callForPopup(): Promise<void> {
  if (!restrictions.value) return
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'deliveryTimeRathDinenPopup',
    popupProperties: new Map<string, unknown>([
      ['enabledASAP', enabledASAP.value],
      ['enabledScheduled', enabledScheduled.value],
      ['title', orderTypeText.value ?? '']
    ])
  })
}

watch(
  () => clientStore.ClientState.data?.StateOrderData?.DeliveryTime ?? 0,
  (newValue: number, oldValue: number) => {
    if (newValue !== oldValue) {
      refreshTime()
    }
  }
)

watch(
  () => clientStore.ClientState.data?.StateOrderData?.DeliverRightNow ?? true,
  (newValue: boolean, oldValue: boolean) => {
    if (newValue !== oldValue) {
      refreshTime()
    }
  }
)
</script>

<style lang="scss">
.v-delivery-time-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1.1rem;
  padding: 0 15px;

  &-time {
    padding: 15px;
    font-variant-numeric: tabular-nums;
  }
}
</style>
